@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.react-datepicker__navigation {
    top: 12px;
    height: 12px;
    width: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.react-datepicker__navigation--next {
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.react-datepicker__navigation--next:focus {
    outline: 0;
    -moz-outline-style: none;
}

.react-datepicker__navigation--previous {
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.react-datepicker__navigation--previous:focus {
    outline: 0;
    -moz-outline-style: none;
}

.react-datepicker-popper {
    z-index: 20;
}